import { useTranslation } from "react-i18next";
import type { AttributeSchema } from "../../../../types/types.PIM";
import { useRoutePath } from "../../../../util/Routing";
import { useAuthContext } from "../../../../components/Auth";
import moment from "moment";
import { ToggleSwitchV2 } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { LinkAttributeValueSchema } from "../../../../util/zod.util";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const InnerContentText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 2px 0 7px;
`;

const UL = styled.ul`
  padding-left: 8px;
  margin: 5px 0 10px;
  margin-left: 13px;
  li {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: 1.33;
    padding-bottom: 5px;
    &::marker {
      font-size: ${({ theme }) => theme.fontSizes.regular};
    }
    span {
      margin-left: -2px;
      position: relative;
    }
  }
`;

export const AttributeValueDisplay = ({
  attribute,
  isPortfolio,
}: {
  attribute: AttributeSchema;
  isPortfolio: boolean;
}) => {
  const { storePath, adminPath } = useRoutePath();
  const { t } = useTranslation();
  const { roleIsSellerAdmin, user } = useAuthContext();

  switch (attribute.input_type) {
    case "single_select":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "numeric":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "date":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? moment(attribute.values[0].value as string)?.format("MM/DD/YYYY")
            : "--"}
        </InnerContentText>
      );
    case "form_field":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "multiline_entry":
      return (
        <>
          {attribute.values?.length ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  `<div class="ql-editor">${
                    attribute.values[0].value as string
                  }</div>` || "--",
              }}
            />
          ) : (
            <InnerContentText>--</InnerContentText>
          )}
        </>
      );
    case "toggle":
      return (
        <>
          <ToggleSwitchV2
            label={attribute.display_name ? t([attribute.display_name]) : ""}
            name={attribute.name}
            defaultChecked={
              attribute.values?.length
                ? (attribute.values[0].value as boolean)
                : false
            }
            key={attribute.id}
            disabled={true}
          />
        </>
      );
    case "multi_select":
      return attribute.values?.length ? (
        <UL>
          {attribute.values.map((value) => {
            return (
              <li key={value.id}>
                <span>{value.enum_variant?.variant}</span>
              </li>
            );
          })}
        </UL>
      ) : (
        <InnerContentText>--</InnerContentText>
      );
    case "link":
      const parsed = LinkAttributeValueSchema.safeParse(
        attribute.values?.[0]?.value
      );

      if (parsed.success && parsed.data.url) {
        return (
          <InnerContentText>
            <a href={parsed.data.url} target={"_blank"} rel="noreferrer">
              {parsed.data.display_text}
            </a>
          </InnerContentText>
        );
      } else if (
        parsed.success &&
        !parsed.data.url &&
        parsed.data.display_text !== ""
      ) {
        return <span>{parsed.data.display_text}</span>;
      } else return <span>--</span>;
    case "product_reference":
      return attribute.values?.length ? (
        <UL>
          {attribute.values.map((value) => {
            const tenantHasAccess =
              value?.product_ref?.visibility_tenants?.find(
                (tenant) => tenant?.id === user?.tenant_id
              )?.id;
            return (
              <li key={value.id}>
                {(value?.product_ref?.status === "published" &&
                  value?.product_ref?.is_visible_on_storefronts &&
                  (value?.product_ref?.is_accessible || tenantHasAccess)) ||
                roleIsSellerAdmin ||
                (value?.product_ref?.status === "published" &&
                  value?.product_ref?.is_accessible &&
                  user) ||
                (value?.product_ref?.status === "published" &&
                  tenantHasAccess) ? (
                  <Link
                    to={
                      isPortfolio
                        ? `${storePath}/product/${value?.product_ref?.slug}`
                        : `${adminPath}/pim/products/${
                            value?.product_ref?.product_number ??
                            value?.product_ref?.id
                          }`
                    }
                  >
                    {value.product_ref?.name}
                  </Link>
                ) : (
                  <>
                    <span
                      data-tip={
                        value?.product_ref?.status !== "published"
                          ? t("Product informations is unavailable to view")
                          : t("Product informations is not accessible")
                      }
                      data-for={value.id}
                    >
                      {value.product_ref?.name}
                    </span>
                    <ReactTooltip
                      delayHide={200}
                      id={value.id}
                      effect="solid"
                    />
                  </>
                )}
              </li>
            );
          })}
        </UL>
      ) : (
        <InnerContentText>--</InnerContentText>
      );
    default:
      return (
        <InnerContentText>
          {attribute.values?.length
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
  }
};
