import { useState } from "react";
import { TertiaryButtonMedium, TextButton } from "../Buttons/Buttons";
import { useTranslation } from "react-i18next";
import type { Assets } from "../../types/types.PIM";
import {
  get_content_type,
  GetDocumentIcon,
  TrimmedName,
} from "../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import styled from "styled-components";
import { DocumentPreview } from "../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/DocumentPreview";

const TertiaryButtonContainer = styled(TertiaryButtonMedium)`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 280px;
`;

export const DocumentFilterTag = ({ documents }: { documents: Assets[] }) => {
  const [show_more, set_show_more] = useState(false);
  const [show_preview, set_show_preview] = useState(false);
  const [selected_document, set_selected_document] =
    useState<Assets | undefined>();
  const { t } = useTranslation();

  const on_document_click = (item: Assets) => {
    set_selected_document(item);
    set_show_preview(true);
  };

  const items_to_show =
    documents.length > 12 && !show_more ? documents.slice(0, 7) : documents;
  const remaining_items = documents.length - items_to_show.length;
  return (
    <>
      {items_to_show.map((item) => (
        <TertiaryButtonContainer
          key={item.id}
          onClick={() => on_document_click(item)}
        >
          <GetDocumentIcon
            asset_type={item.asset_type}
            file_type={get_content_type(item.content_type ?? "application/pdf")}
          />
          <div style={{ maxWidth: "80%" }}>
            <TrimmedName text={item.name} />
          </div>
        </TertiaryButtonContainer>
      ))}
      {!!remaining_items && (
        <TextButton
          onClick={() => set_show_more(true)}
        >{`+ ${remaining_items} ${t("more")}`}</TextButton>
      )}
      {show_preview && selected_document && (
        <DocumentPreview
          onCancelPreview={() => set_show_preview(false)}
          selected_asset_idx={documents.findIndex(
            (item) => item.id === selected_document.id
          )}
          assets={documents.map((item) => ({
            ...item,
            can_edit: false,
            can_delete: false,
            can_change_visibility: false,
            can_external_download: true,
            can_view_details: true,
          }))}
          onDownload={(asset) => window.open(asset.signed_url, "_blank")}
        />
      )}
    </>
  );
};
