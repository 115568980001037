import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components/macro";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import type { ProductAttributesSummary } from "../../../types/types.PIM";
import { useSWRInfinite } from "swr";
import {
  construct_product_infinite_scroll_query,
  CustomH3,
  PAGE_SIZE,
  TotalProducts,
} from "../pages.public.util";
import type {
  TagClassificationConfig,
  WithPagination,
} from "../../../types/types";
import { screenSize } from "../../../theme";
import { useTranslation } from "react-i18next";
import { ErrorPlaceholder } from "../../../components/Error";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { FiltersArea } from "../../../components/Filters/Filters";
import { Loader } from "../../../components/Loader/Loader";
import { useInView } from "react-intersection-observer";
import { endpoints } from "../../../endpoints";
import { useStoreState } from "../../../util/util";
import { H3 } from "../../../components/Typography/Typography";
import { useDebounce } from "../../../util/hooks";
import { StringParam, useQueryParams } from "use-query-params";
import { AttributeValueDisplay } from "../../SharedPages/ProductDetailPage/ProductOverview/product_overview.utils";

const Popout = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

const PopupFade = keyframes`
    0% { transform: scale(0.4)}
    50% {transform: scale(0.7)}
    100% {transform: scale(1)}
`;

const SearchPopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.overlayColor};
  z-index: 900;
  overflow: hidden;
  animation-name: ${Popout};
  animation-duration: 0.3s;
  .__react_component_tooltip.place-top::before {
    bottom: -1px;
    height: 8px !important;
  }
`;

const PopupContainer = styled.div<{
  left_position: string;
  top_position: string;
}>`
  max-width: 1400px;
  min-width: 300px;
  display: flex;
  background: ${({ theme }) => theme.primaryBG};
  position: absolute;
  left: ${({ left_position }) => left_position};
  top: ${({ top_position }) => top_position};
  margin-right: 70px;
  height: ${({ top_position }) => `calc(100% - (${top_position} + 25px))`};
  width: ${({ left_position }) => `calc(100% - (${left_position} + 70px))`};
  animation-name: ${PopupFade};
  animation-duration: 0.3s;
  border-radius: 5px;
  padding: 8px;
  gap: 8px;
  @media ${screenSize.medium} {
    flex-direction: column-reverse;
    width: ${({ left_position }) => `calc(100% - (${left_position} + 25px))`};
  }
`;

const SectionDivider = styled.div`
  display: none;
  @media ${screenSize.medium} {
    display: block;
    height: 1px;
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  }
`;

const SearchBarContainer = styled.div<{
  search_bar_left_position: number;
  search_bar_top_position: number;
}>`
  position: absolute;
  left: ${({ search_bar_left_position }) => `${search_bar_left_position}px`};
  top: ${({ search_bar_top_position }) => `${search_bar_top_position}px`};
  width: 100%;
  height: fit-content;
`;

const ProductsSection = styled.div`
  display: flex;
  flex: 0.65;
  flex-direction: column;
  height: 100%;
  max-width: 65%;
  position: relative;
  @media ${screenSize.medium} {
    max-height: 65%;
    max-width: 100%;
  }
`;

const FiltersSection = styled.div`
  flex: 0.35;
  height: 100%;
  overflow-y: scroll;
  @media ${screenSize.medium} {
    max-height: 35%;
    width: 100%;
    overflow-x: scroll;
  }
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow-y: scroll;
`;

const ProductItem = styled.div`
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  padding: 8px;
  flex-direction: column;
  gap: 8px;
  .ql-editor {
    padding: 0;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;

const EmptyProductsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductSearchPopup = <
  ProductType extends ProductAttributesSummary
>({
  search_bar_left_position,
  search_bar_top_position,
  parent_search_query,
  placeholder,
  handle_parent_search,
  search_bar_max_width,
  custom_labels,
  close_search_popup,
  clear_filter,
}: {
  search_bar_left_position: number;
  search_bar_top_position: number;
  parent_search_query: string;
  placeholder: string;
  handle_parent_search: (e: React.ChangeEvent<HTMLInputElement>) => void;
  close_search_popup: () => void;
  search_bar_max_width?: string;
  custom_labels?: TagClassificationConfig[];
  clear_filter: () => void;
}) => {
  const [search_query, set_search_query] = useState(parent_search_query);
  const [debounced_search_query, set_debounced_search_query] = useDebounce(
    search_query,
    1000
  );
  const [, setQuery] = useQueryParams({
    q_popup: StringParam,
  });
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const {
    data: productsResponse,
    error: productsError,
    setSize,
    size,
  } = useSWRInfinite<WithPagination<{ data: ProductType[] }>>(
    (index, previousPageData) =>
      construct_product_infinite_scroll_query({
        baseUrl:
          endpoints.v2_tenants_tenant_id_pim_products_attributes_summary(
            tenant_id
          ),
        query: debounced_search_query,
        previousPageData,
        index,
        pageSize: PAGE_SIZE,
      }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { ref: observer_ref, inView } = useInView({ triggerOnce: true });

  const handle_search = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val) {
      set_search_query(e.target.value);
    } else {
      handle_clear_search();
    }
  };

  const handle_clear_search = () => {
    set_search_query("");
    set_debounced_search_query("");
    setQuery({ q_popup: undefined });
    handle_parent_search({
      target: { value: "" },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
    close_search_popup();
  };

  const on_enter_press = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handle_parent_search({
          target: { value: search_query },
        } as unknown as React.ChangeEvent<HTMLInputElement>);
        setQuery({ q_popup: undefined });
        close_search_popup();
      }
    },
    [close_search_popup, handle_parent_search, search_query, setQuery]
  );

  useEffect(() => {
    setQuery({
      q_popup: debounced_search_query ? debounced_search_query : undefined,
    });
    if (debounced_search_query) {
      setSize(1);
    }
  }, [debounced_search_query, setQuery, setSize]);

  useEffect(() => {
    if (parent_search_query) {
      document.getElementById("search_popup_search_bar")?.focus();
    }
  }, [parent_search_query]);

  useEffect(() => {
    if (inView) {
      setSize((size) => size + 1);
    }
  }, [inView, setSize]);

  useEffect(() => {
    document.addEventListener("keyup", on_enter_press);

    return () => {
      document.removeEventListener("keyup", on_enter_press);
    };
  }, [on_enter_press]);

  const top_position = `${search_bar_top_position + 48}px`;

  const products = productsResponse ? [...productsResponse] : [];

  const is_loading_initial_data = !productsResponse && !productsError;

  const is_loading_more_data =
    is_loading_initial_data ||
    (size > 0 &&
      productsResponse &&
      typeof productsResponse[size - 1] === "undefined");

  return ReactDOM.createPortal(
    <SearchPopupOverlay>
      <SearchBarContainer
        search_bar_left_position={search_bar_left_position}
        search_bar_top_position={search_bar_top_position}
      >
        <SearchBar
          query={search_query}
          placeHolder={placeholder}
          handleChange={handle_search}
          handleClearInput={handle_clear_search}
          maxWidth={search_bar_max_width}
          dataTestId="search_popup_search_bar"
          id="search_popup_search_bar"
        />
      </SearchBarContainer>
      <PopupContainer
        top_position={top_position}
        left_position={`${search_bar_left_position}px`}
      >
        <ProductsSection>
          {productsError ? (
            <ErrorPlaceholder
              message={t("Could not load products, please try again later.")}
            />
          ) : (
            <>
              {is_loading_initial_data ? (
                <DelayedSpinner />
              ) : (
                <>
                  <TotalProducts>
                    {products[0].pagination.total} {t("products")}
                  </TotalProducts>
                  <ProductsContainer>
                    {products[0].data.length === 0 ? (
                      <EmptyProductsContainer>
                        <H3>{t("No products found")}</H3>
                      </EmptyProductsContainer>
                    ) : (
                      products.map((products_response) =>
                        products_response.data.map((product, idx) => (
                          <Fragment key={product.product_id}>
                            <ProductItem>
                              <CustomH3 title={product.product_name}>
                                {product.product_name}
                              </CustomH3>
                              {product.attributes.reduce<JSX.Element[]>(
                                (acc, attr) => {
                                  if (!!attr.values?.[0]?.value) {
                                    acc.push(
                                      <AttributeValueDisplay
                                        key={attr.id}
                                        attribute={attr}
                                        isPortfolio={true}
                                      />
                                    );
                                  }
                                  return acc;
                                },
                                []
                              )}
                            </ProductItem>
                            {is_loading_more_data &&
                              idx === products_response.data.length - 1 && (
                                <LoaderContainer>
                                  <Loader
                                    isLoading={is_loading_more_data}
                                    omitContainer={true}
                                  />
                                </LoaderContainer>
                              )}
                            {idx === PAGE_SIZE - 2 && (
                              <div
                                key={product.product_id + "observer"}
                                ref={observer_ref}
                              />
                            )}
                          </Fragment>
                        ))
                      )
                    )}
                  </ProductsContainer>
                </>
              )}
            </>
          )}
        </ProductsSection>
        <SectionDivider />
        <FiltersSection>
          <FiltersArea
            is_search_filter_popup={true}
            customLabels={custom_labels ?? []}
            clearFilter={clear_filter}
            exit_search_filter_popup={handle_clear_search}
          />
        </FiltersSection>
      </PopupContainer>
    </SearchPopupOverlay>,
    document.body
  );
};
