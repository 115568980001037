import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Split from "react-split";
import useSWR, { useSWRInfinite } from "swr";
import { StringParam, useQueryParams } from "use-query-params";
import { ErrorPlaceholder } from "../../../components/Error";
import { Loader } from "../../../components/Loader/Loader";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { SoftHeader2 } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import {
  ContentWrapper,
  PageWrapper,
  PortfolioCaption,
  PortfolioContentLayout,
  PortfolioHeader,
  PortfolioSearch,
  PortfolioTitle,
  ProductContentWrapper,
  ProductListItemWrapper,
  ProductListMainWrapper,
  SelectedProductListItemWrapper,
} from "../../../layout/publicPageLayout";
import type {
  OptionType,
  PortfolioViewType,
  TagClassificationConfig,
} from "../../../types/types";
import type {
  PIMProduct,
  PIMProductPaginatedOutput,
} from "../../../types/types.PIM";
import { useDebounce } from "../../../util/hooks";
import {
  ConditionalWrapper,
  createStorefrontMetadataLocalization,
  updateCustomLabels,
  useMediaQueries,
  useStoreState,
  useSupportedLanguages,
} from "../../../util/util";
import { PIMProductDetailsPortfolio } from "../ProductDetailsPortfolio/PIMProductDetailsPortfolio";
import {
  CasNumber,
  CustomH3,
  CustomProductName,
  LoaderContainer,
  PAGE_SIZE,
  PRODUCT_SEARCH_POPUP,
  TotalProducts,
  construct_product_infinite_scroll_query,
  getBrandName,
  getProductName,
  usePortfolioUpdateHeight,
} from "../pages.public.util";
import { FiltersFlyBar } from "../../../components/Filters/FiltersFlyBar";
import { FullWidthHorizontalSeparator } from "../../../layout/portalPageLayout";
import { SelectedFiltersArea } from "../../../components/Filters/SelectedFiltersArea";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { PortfoliolayoutOptions, groupPortfolioParamsByKey } from "..";
import { useForm } from "react-hook-form";
import { SelectPortfolioView, ViewIconWrapper } from "./PortfolioClassicView";
import { GridIcon } from "../../../components/Icons/Icons";
import { screenSize } from "../../../theme";
import { RequestUnlistedProduct } from "../../Buyer/RequestUnlistedProduct/RequestUnlistedProduct";
import { useAuthContext } from "../../../components/Auth";
import { ProductSearchPopup } from "../ProductDetailsPortfolio/ProductSearchPopup";

const FullwidthPageWrapper = styled(PageWrapper)`
  max-width: 100%;
`;

const ContentContainer = styled.div<{ showFilters: boolean }>`
  padding: ${({ showFilters }) =>
    showFilters ? "0 30px 0 0" : "0 40px 0 50px"};
  display: flex;
  flex-direction: row;
  position: relative;
  @media ${screenSize.medium} {
    padding: ${({ showFilters }) => (showFilters ? "0 15px 0 0" : "0 15px")};
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

type PaginationType = {
  offset: number;
  order_by: "desc" | "asc";
  total: number;
  limit: number;
};

type ProductsAPIResponse = {
  data: PIMProduct[];
  pagination: PaginationType;
};

function replaceProductSlug({
  path,
  search,
  hash,
  slug,
  isPublic,
}: {
  path: string;
  search: string;
  hash: string;
  slug: string;
  isPublic?: boolean;
}): string {
  const pathParts = path.split("/");

  if (pathParts[3] !== "product" && isPublic) {
    // page has been loaded with just the tenant slug and no product is
    // specified (usually with `/portfolio` instead of `/product` in URL).

    const newPathParts = pathParts.map((part, index) =>
      index === 3 ? `product/${slug}` : part
    );

    return newPathParts.join("/") + search + hash;
  } else {
    // initial page load has been completed or there was already a
    // product in the URL.
    const baseUrl = [...pathParts.slice(0, isPublic ? 4 : 6), slug].join("/");
    return baseUrl + search + hash;
  }
}

/**
 * Requirements:
  1. individual products should be linkable ✓
  2. filters should be linkable, and should show up as applied on the UI ✓
  3. search queries should be linkable and show up prefilled in the search bar. ✓
  4. when going to the portfolio page from anywhere else the back button should work with one click, even though the URL changes to include the product. ✓
  5. Clicking back button when going through multiple products should update the selected product on the UI. ✓
  6. if a product is not selected in the URL, then the first product should be selected. ✓
  7. when applying filters or a search the first product of the result set should be selected. ✓
  8. based on #6 and #7 it should be impossible to link to a product that is not in the side scrolling section based on the currently applied filters/search ✓
  9. clearing filters or search should reset first product ✓
  10. clear filter for individual filters should not affect the general clear filter ✓
 */
export const PortfolioModernView = ({
  setPortfolioLayout,
  isPublic,
}: {
  setPortfolioLayout: React.Dispatch<React.SetStateAction<PortfolioViewType>>;
  isPublic: boolean;
}) => {
  const [product, setProduct] = useState<PIMProduct>();
  const {
    roleIsBuyerAdmin,
    roleIsDistributor,
    roleIsDistributorAdmin,
    roleIsSomeKindOfSeller,
  } = useAuthContext();

  let { productSlug } =
    useParams<{
      productSlug?: string;
    }>();
  const [productDetailsMode, setProductDetailsMode] = useState(
    productSlug ? true : false
  );
  const [focusSearch, setFocusSearch] = useState(false);
  const [show_enterprise_search, set_show_enterprise_search] = useState(false);
  const [showFilters, setShowFilters] = useState(productSlug ? false : true);

  const search_ref = React.useRef<HTMLDivElement>(null);

  const {
    slug: storefrontSlug,
    storefront_metadata,
    storefront_id,
  } = useStoreState();
  const [cookies] = useCookies([`preferred-language-${storefrontSlug}`]);
  const preferred: string | undefined =
    cookies[`preferred-language-${storefrontSlug}`];
  const { header, sub_header, placeholder } =
    createStorefrontMetadataLocalization({
      storefront_metadata,
      cookie: preferred,
    });
  const { supportedLanguageOptions } = useSupportedLanguages();

  const {
    data: productFromURL,
    error: productFromURLError,
    mutate: mutateProduct,
  } = useSWR<PIMProduct>(
    productSlug
      ? endpoints.v2_storefronts_id_pim_products_id(storefront_id, productSlug)
      : null,
    {
      onSuccess: (product) => {
        setShowFilters(false);
        setProductDetailsMode(true);
        setProduct(product);
      },
      revalidateOnFocus: false,
    }
  );

  const { data: customLabels, error: customLabelsError } = useSWR<
    TagClassificationConfig[]
  >(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters_customLabels(storefront_id)
      : null
  );

  updateCustomLabels(customLabels, supportedLanguageOptions);

  const { search } = useLocation();

  const [query, setQuery] = useQueryParams({
    q: StringParam,
    active_tag_view: StringParam,
  });

  const location = useLocation();
  const history = useHistory();

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q ?? "");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounce(
    searchQuery,
    1000
  );
  const { t } = useTranslation();
  const { errors, formState } = useForm();

  function resetPageURL({
    path,
    search,
    hash,
  }: {
    path: string;
    search: string;
    hash: string;
  }): string {
    const pathParts = path.split("/");
    const baseUrl = [
      ...pathParts.slice(0, 3),
      isPublic ? "portfolio" : "admin/pim/products",
    ].join("/");
    return baseUrl + search + hash;
  }

  let filtersFromUrl: { [key: string]: string[] } = {};
  (() => {
    const params = new URLSearchParams(search.substring(1));
    filtersFromUrl = groupPortfolioParamsByKey(params);
  })();

  const {
    data: productsResponse,
    error: productsError,
    setSize,
    size,
    mutate,
  } = useSWRInfinite<PIMProductPaginatedOutput>(
    (index, previousPageData) =>
      storefront_id
        ? construct_product_infinite_scroll_query({
            baseUrl: endpoints.v2_storefronts_id_pim_products(storefront_id),
            query: debouncedSearchQuery,
            filtersFromUrl,
            previousPageData,
            index,
            pageSize: 30,
          })
        : null,
    // We don't revalidate here because when we did revalidate that was
    // causing the quote request item form to unmount, re-mount, and
    // lose any changes the user had made to the form (entering or changing
    // inputs).  See: https://app.asana.com/0/1200568265088077/1201211536022320/f
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const products: ProductsAPIResponse[] = productsResponse
    ? [...productsResponse]
    : [];

  const hasProducts = !productsError && products;
  const isLoadingInitialData = !productsResponse && !productsError;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 &&
      productsResponse &&
      typeof productsResponse[size - 1] === "undefined");

  const { ref, inView } = useInView({ triggerOnce: true });

  const { productListHeight, setTriggerListHeightUpdate, heightRef } =
    usePortfolioUpdateHeight(product);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFocusSearch(true);
    const val = e.target.value;
    if (val === "") {
      handleClearSearch();
    } else {
      if (productFromURL) {
        const searchTxt = new URLSearchParams(location.search).get("q");
        setProduct(undefined);
        const destination = resetPageURL({
          path: location.pathname,
          search: searchTxt
            ? location.search.replace(searchTxt, val)
            : location.search.includes("?")
            ? `${location.search}&q=${val}`
            : `${location.search}?q=${val}`,
          hash: location.hash,
        });
        history.replace(destination);
      }
      setSearchQuery(val);
      localStorage.removeItem(PRODUCT_SEARCH_POPUP);
    }

    setSize(1);
  };

  const handleClearSearch = () => {
    setProduct(undefined);
    const destination = resetPageURL({
      path: location.pathname,
      search: location.search,
      hash: location.hash,
    });
    history.replace(destination);
    setSearchQuery("");
    setDebouncedSearchQuery("");
    setQuery({ q: undefined });
  };

  const handleClearFilter = () => {
    setProduct(undefined);
    const destination = resetPageURL({
      path: location.pathname,
      search: query.q ? `?q=${query.q}` : "",
      hash: location.hash,
    });
    history.replace(destination);
  };

  const handleShowFilters = (showFilters: boolean) => {
    setShowFilters(showFilters);
    if (!showFilters && productSlug) {
      setProductDetailsMode(true);
    }
  };

  /**
   * Store the new product in React state and update the URL with the new slug.
   */
  const handleProductClick = (clickedProduct: PIMProduct) => {
    setProductDetailsMode(true);
    if (product?.id !== clickedProduct.id) {
      const replace_tab_query = location.search.replace(/tab=[0-9]{1,2}/, "");
      setProduct(clickedProduct);
      const destination = replaceProductSlug({
        path: location.pathname,
        search: replace_tab_query,
        hash: location.hash,
        slug: clickedProduct.slug,
        isPublic,
      });
      // if (isPublic) {
      history.push(destination);
      // }
    }
  };

  const resetPage = (search: string) => {
    const destination = resetPageURL({
      path: location.pathname,
      search: search,
      hash: location.hash,
    });
    setProductDetailsMode(false);
    setProduct(undefined);
    history.push(destination);
  };

  const handleViewChange = (option: OptionType<PortfolioViewType>) => {
    sessionStorage.setItem("portfolioView", option.value);
    setPortfolioLayout(option.value);
    if (productFromURL) {
      mutate();
      setProduct(productFromURL);
    }
  };

  const close_search_popup = () => {
    set_show_enterprise_search(false);
    localStorage.setItem(PRODUCT_SEARCH_POPUP, "closed");
  };

  useEffect(() => {
    if (!isPublic) {
      mutate();
    }
  }, [history, mutate, isPublic]);

  useEffect(() => {
    setQuery({ q: debouncedSearchQuery ? debouncedSearchQuery : undefined });
    const should_search_popup_be_closed = Boolean(
      localStorage.getItem(PRODUCT_SEARCH_POPUP)
    );
    set_show_enterprise_search(
      !should_search_popup_be_closed &&
        !!debouncedSearchQuery &&
        storefront_metadata.enable_vertex_ai_search
    );
    if (!debouncedSearchQuery) {
      localStorage.removeItem(PRODUCT_SEARCH_POPUP);
    }
  }, [
    debouncedSearchQuery,
    setQuery,
    storefront_metadata.enable_vertex_ai_search,
  ]);

  useEffect(() => {
    return history.listen((curLocation) => {
      if (history.action === "POP") {
        const searchTxt = new URLSearchParams(curLocation.search).get("q");
        setSearchQuery(searchTxt ?? "");
        setDebouncedSearchQuery(searchTxt ?? "");
      }
    });
    // This is where I go against the system.
    // The eslint disable line below is necessary because, I want this effect to trigger
    // ONLY when history changes.
    // the other dependency it's crying about, is the setDebouncedSearchQuery
    // which has no BAD effect when removed here.
    //eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    if (searchQuery) {
      if (history.action === "PUSH") {
        document.getElementById("portfolio-products-search-bar")?.focus();
        setFocusSearch(false);
      }
    }
  }, [focusSearch, searchQuery, history]);

  useEffect(() => {
    // If the observer is the viewport update size of documents to fetched
    if (inView) {
      setSize((size) => size + 1);
    }
  }, [inView, setSize]);

  const { isMediumScreen, isLargeScreen } = useMediaQueries();
  useEffect(() => {
    if (product) {
      setShowFilters(false);
    }
  }, [product]);

  useEffect(() => {
    if (showFilters) {
      setProductDetailsMode(false);
    }
  }, [showFilters]);

  const search_bar_left_position = search_ref.current?.getBoundingClientRect()
    .left
    ? Math.ceil(search_ref.current?.getBoundingClientRect().left)
    : 0;
  const search_bar_top_position = search_ref.current?.getBoundingClientRect()
    .top
    ? Math.ceil(search_ref.current?.getBoundingClientRect().top)
    : 0;

  return (
    <FullwidthPageWrapper>
      {isPublic ? (
        <PortfolioHeader>
          <>
            {/* Header and sub_header are not translated because these are user managed strings(t). */}
            {/* Because of Hierachy of Designs, it is OK that the portfolio title is bigger than other titles in other pages */}
            {/* To be more specific, the size of the product names on this page, already equals the font size of titles in other pages */}
            {/* Hence, Portfolio title should be bigger, to maintain that typography flow; from top to bottom.*/}
            <PortfolioTitle>{header}</PortfolioTitle>
            <PortfolioCaption>{sub_header}</PortfolioCaption>
          </>
          <FullWidthHorizontalSeparator />
        </PortfolioHeader>
      ) : (
        <FullWidthHorizontalSeparator />
      )}

      <ContentContainer showFilters={showFilters}>
        <FiltersFlyBar
          customLabels={customLabels ?? []}
          handleShowFilters={handleShowFilters}
          show={showFilters}
          resetPage={resetPage}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: showFilters || !productFromURL ? "20px" : 0,
          }}
        >
          <PortfolioSearch
            style={{
              marginLeft: isMediumScreen && !showFilters ? "50px" : "0",
            }}
          >
            <SearchContainer>
              <SearchBar
                query={searchQuery}
                // placeholder string is managed by users via storefront_metadata
                placeHolder={placeholder}
                handleChange={handleSearch}
                handleClearInput={handleClearSearch}
                dataTestId="portfolio-products-search-bar"
                id="portfolio-products-search-bar"
                ref={search_ref}
              />
              {storefront_metadata.unlisted_product_requests_enabled &&
                searchQuery.length > 0 &&
                (roleIsBuyerAdmin ||
                  roleIsDistributor ||
                  roleIsDistributorAdmin) && <RequestUnlistedProduct />}
            </SearchContainer>
            <SelectPortfolioView>
              <ViewIconWrapper>
                <GridIcon width={22} height={22} />
              </ViewIconWrapper>
              <SelectBoxV2
                name="view_type"
                placeholder=""
                defaultValue={{ value: "modern", label: "Modern" }}
                isCreatable={false}
                isSearchable={false}
                onChange={handleViewChange}
                errors={errors}
                formState={formState}
                options={[
                  ...PortfoliolayoutOptions,
                  ...(!isPublic ? [{ value: "list", label: "List" }] : []),
                ]}
              />
            </SelectPortfolioView>
          </PortfolioSearch>

          {!customLabelsError && (
            <>
              <SelectedFiltersArea
                clearFilter={handleClearFilter}
                filtersList={
                  customLabels?.map(
                    (filter: TagClassificationConfig) => filter.filter_type
                  ) || []
                }
                isPublic={isPublic}
              />
            </>
          )}

          <ContentWrapper>
            <TotalProducts data-testid="portfolio-total-products">
              {!isLoadingInitialData &&
                products.length &&
                `${products[0].pagination.total} ${t("products")}`}
            </TotalProducts>
            <PortfolioContentLayout>
              {productDetailsMode && (
                <Split
                  sizes={[25, 75]}
                  minSize={220}
                  expandToMin={false}
                  gutterSize={7}
                  gutterAlign="center"
                  snapOffset={30}
                  dragInterval={1}
                  direction={"horizontal"}
                  cursor="col-resize"
                  style={{
                    display: "flex",
                    flexDirection: isLargeScreen ? "column" : "row",
                    width: "100%",
                  }}
                >
                  <ProductListMainWrapper
                    data-testid="product-list-container"
                    style={{
                      height: productListHeight,
                    }}
                  >
                    {isLoadingInitialData && (
                      <LoaderContainer>
                        <Loader
                          isLoading={isLoadingInitialData}
                          omitContainer={true}
                        />
                      </LoaderContainer>
                    )}
                    {hasProducts &&
                      products.map((nested: ProductsAPIResponse) => {
                        return nested.data.map(
                          (listProduct: PIMProduct, index: number) => {
                            return (
                              <React.Fragment key={listProduct.id}>
                                {/* Conditionally wrap the items in selected/unselected styled components */}
                                <ConditionalWrapper
                                  condition={listProduct.id === product?.id}
                                  wrapper={(children) => (
                                    <SelectedProductListItemWrapper
                                      as="div"
                                      onClick={() => {
                                        setShowFilters(false);
                                        setProductDetailsMode(true);
                                      }}
                                    >
                                      {children}
                                    </SelectedProductListItemWrapper>
                                  )}
                                  falseWrapper={(children) => (
                                    <ProductListItemWrapper
                                      onClick={() =>
                                        handleProductClick(listProduct)
                                      }
                                    >
                                      {children}
                                    </ProductListItemWrapper>
                                  )}
                                >
                                  <CustomH3
                                    title={
                                      getProductName(listProduct) ?? undefined
                                    }
                                  >
                                    {getProductName(listProduct)}
                                  </CustomH3>
                                  {!!getBrandName(listProduct) && (
                                    <SoftHeader2>
                                      <CustomProductName
                                        title={
                                          getBrandName(listProduct) ?? undefined
                                        }
                                      >
                                        {/* TODO check to see if this looks ok without the brand_name, it will likely de deprecated in the future */}
                                        {getBrandName(listProduct)}
                                      </CustomProductName>
                                    </SoftHeader2>
                                  )}
                                  {!!listProduct.identifiers.cas_number && (
                                    <SoftHeader2>
                                      <CasNumber>
                                        {`CAS # ${listProduct.identifiers.cas_number}`}
                                      </CasNumber>
                                    </SoftHeader2>
                                  )}
                                </ConditionalWrapper>
                                {/* If we get to the end and the request hasn't finished yet display spinner */}
                                {!!isLoadingMore &&
                                  index === nested.data.length - 1 && (
                                    <LoaderContainer>
                                      <Loader
                                        isLoading={!!isLoadingMore}
                                        omitContainer={true}
                                      />
                                    </LoaderContainer>
                                  )}
                                {/* Display intersection observer ref when we get towards the end.
                          We fetch products in multiples of PAGE_SIZE so if the length of this array
                          is < PAGE_SIZE we don't need to show the observer ref.
                          This is set to PAGE_SIZE - 4 so that unless the user is scrolling quickly more 
                          products will load before hitting bottom
                        */}
                                {index === PAGE_SIZE - 4 && (
                                  <div
                                    key={listProduct.id + "observer"}
                                    ref={ref}
                                  />
                                )}
                              </React.Fragment>
                            );
                          }
                        );
                      })}
                  </ProductListMainWrapper>
                  <ProductContentWrapper ref={heightRef}>
                    {products && products[0]?.data?.length === 0 && (
                      <ErrorPlaceholder message={t("No products found")} />
                    )}
                    {!products && productsError && (
                      <ErrorPlaceholder
                        message={t(
                          "There was an error fetching products for this storefront"
                        )}
                      />
                    )}
                    {products &&
                      productSlug &&
                      productFromURLError &&
                      !product && (
                        <ErrorPlaceholder message={t("Product not found")} />
                      )}
                    {productSlug &&
                      product &&
                      products &&
                      products[0]?.data?.length > 0 && (
                        <PIMProductDetailsPortfolio
                          product={product}
                          mutateProduct={mutateProduct}
                          error={productFromURLError}
                          updateListHeight={setTriggerListHeightUpdate}
                        />
                      )}
                  </ProductContentWrapper>
                </Split>
              )}
              {!productDetailsMode && (
                <ProductListMainWrapper
                  data-testid="product-list-container"
                  style={{
                    height: productListHeight,
                  }}
                  gridView={true}
                >
                  {isLoadingInitialData && (
                    <LoaderContainer>
                      <Loader
                        isLoading={isLoadingInitialData}
                        omitContainer={true}
                      />
                    </LoaderContainer>
                  )}
                  {products && products[0]?.data?.length === 0 && (
                    <ErrorPlaceholder message={t("No products found")} />
                  )}
                  {!products && productsError && (
                    <ErrorPlaceholder
                      message={t(
                        "There was an error fetching products for this storefront"
                      )}
                    />
                  )}
                  {hasProducts &&
                    products.map((nested: ProductsAPIResponse) => {
                      return nested.data.map(
                        (listProduct: PIMProduct, index: number) => {
                          return (
                            <React.Fragment key={listProduct.id}>
                              {/* Conditionally wrap the items in selected/unselected styled components */}
                              <ConditionalWrapper
                                condition={listProduct.id === product?.id}
                                wrapper={(children) => (
                                  <SelectedProductListItemWrapper
                                    as="div"
                                    gridView={true}
                                    onClick={() => {
                                      setShowFilters(false);
                                      setProductDetailsMode(true);
                                    }}
                                  >
                                    {children}
                                  </SelectedProductListItemWrapper>
                                )}
                                falseWrapper={(children) => (
                                  <ProductListItemWrapper
                                    onClick={() =>
                                      handleProductClick(listProduct)
                                    }
                                    gridView={true}
                                  >
                                    {children}
                                  </ProductListItemWrapper>
                                )}
                              >
                                <CustomH3
                                  title={
                                    getProductName(listProduct) ?? undefined
                                  }
                                >
                                  {getProductName(listProduct)}
                                </CustomH3>
                                {!!getBrandName(listProduct) && (
                                  <SoftHeader2>
                                    <CustomProductName
                                      title={
                                        getBrandName(listProduct) ?? undefined
                                      }
                                    >
                                      {/* TODO check to see if this looks ok without the brand_name, it will likely de deprecated in the future */}
                                      {getBrandName(listProduct)}
                                    </CustomProductName>
                                  </SoftHeader2>
                                )}
                                {!!listProduct.identifiers.cas_number && (
                                  <SoftHeader2>
                                    <CasNumber>
                                      {`CAS # ${listProduct.identifiers.cas_number}`}
                                    </CasNumber>
                                  </SoftHeader2>
                                )}
                              </ConditionalWrapper>
                              {/* If we get to the end and the request hasn't finished yet display spinner */}
                              {!!isLoadingMore &&
                                index === nested.data.length - 1 && (
                                  <LoaderContainer>
                                    <Loader
                                      isLoading={!!isLoadingMore}
                                      omitContainer={true}
                                    />
                                  </LoaderContainer>
                                )}
                              {/* Display intersection observer ref when we get towards the end.
                      We fetch products in multiples of PAGE_SIZE so if the length of this array
                      is < PAGE_SIZE we don't need to show the observer ref.
                      This is set to PAGE_SIZE - 4 so that unless the user is scrolling quickly more 
                      products will load before hitting bottom
                    */}
                              {index === 20 && (
                                <div
                                  key={listProduct.id + "observer"}
                                  ref={ref}
                                  style={{
                                    display: "inline",
                                    width: "0",
                                    height: "0",
                                  }}
                                />
                              )}
                            </React.Fragment>
                          );
                        }
                      );
                    })}
                </ProductListMainWrapper>
              )}
            </PortfolioContentLayout>
            {show_enterprise_search && roleIsSomeKindOfSeller && (
              <ProductSearchPopup
                search_bar_left_position={search_bar_left_position}
                search_bar_top_position={search_bar_top_position}
                parent_search_query={searchQuery}
                placeholder={placeholder}
                handle_parent_search={handleSearch}
                custom_labels={customLabels}
                close_search_popup={close_search_popup}
                clear_filter={handleClearFilter}
              />
            )}
          </ContentWrapper>
        </div>
      </ContentContainer>
    </FullwidthPageWrapper>
  );
};
