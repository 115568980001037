/** Type file for types exclusively related to the PIM. */
import type {
  Identifiers,
  Permission,
  ProductSKU,
  ProductSelectorCSVDocument,
  SupportedLanguage,
  TDSDocument,
  TranslationsSchema,
  UUID,
  WithPagination,
} from "./types";

/**
 * The naming is confusing here, and this is not a named schema on the backend.
 * Its used for a more limited overview of groups on the table page for
 * groups/groups within templates.
 * This type is only used in conjuction with @see TenantAttributeGroupSummary
 */
export type SingleAttributeGroupSummary = {
  id: UUID;
  attribute_count: number;
  name: string;
  description: string | null;
  display_name: string;
  is_system: boolean;
};

/**
 * @see server/src/agilis/docs/odm/TenantAttributeGroupSummarySchema.yaml
 * GET summary of attribute groups for table.
 * TenantAttributeGroupPaginatedOutputSchema
 */
export type TenantAttributeGroupSummary = WithPagination<{
  data: SingleAttributeGroupSummary[];
}>;

/**
 * This is not a named type in the API doc but its used for creating an
 * attribute that is part of a group, kind of an extension of an attribute.
 */
export type SingleAttributeCreation = {
  id: UUID;
  display_order?: number | null;
  is_attribute?: boolean | null; // defaults to true for now, in the future could be a collection.
  display_name?: string | null;
};

/**
 * @see server/src/agilis/docs/odm/AttributeGroupArgsSchema.yaml
 * Creates a new attribute group, with preexisting attributes. Potentially in
 * the future it may be possible to create an empty group without attributes to
 * start with.
 */
export type AttributeGroupArgsSchema = {
  name: string;
  display_name?: string | null;
  description?: string | null;
  attributes: SingleAttributeCreation[] | null;
};

/**
 * @see server/src/agilis/docs/odm/AttributeGroupAttributePatchSchema.yaml
 */
export type AttributeGroupAttributePatchSchema = {
  display_name: string;
  display_order?: number | null;
};

/**
 * @see server/src/agilis/docs/odm/AttributeGroupSchema.yaml
 * detail view of indidividual attribute group.
 */
export type AttributeGroupSchema = {
  items: SingleAttributeCreation[];
  properties: {
    id: UUID;
    name: string;
  };
  is_system: boolean;
};

/**
 * @see server/src/agilis/docs/odm/AttributeGroupSummaryPatchSchema.yaml
 * Currently for editing the name of a preexisting group. There is no current
 * way to change the display order of an attribute group. Name and display are
 * currently the same.
 */
export type AttributeGroupSummaryPatch = {
  name: string;
  display_name: string;
  discription: string | null;
};

/**
 * @see server/src/agilis/docs/odm/AttributeGroupSummarySchema.yaml
 * This is for an individual group detail page.
 */
export type AttributeGroupSummary = {
  name: string;
  id: UUID;
  attribute_count: number;
  group_type: string;
  display_name: string;
  is_frozen: boolean;
  description: string | null;
  attributes: AttributeSchema[];
  is_system: boolean;
  is_restricted: boolean;
};

export type ProductReference = {
  id: UUID;
  name: string;
  slug: string;
  product_number: string;
  is_accessible: boolean;
  is_visible_on_storefronts: boolean;
  status: ProductStatusType;
  visibility_tenants: {
    id: UUID;
  }[];
};

export interface IproductReferenceCollectionItem {
  id: string;
  name: string;
  slug: string;
}

// narrow this type based on attributes that are expected to exist
export type AttributeValue = {
  id: UUID;
  priority: number;
  enum_variant: EnumVariantSchema | null;
  attribute_id: UUID;
  value:
    | string
    | boolean
    | null
    | { url: string; display_text: string }
    | IproductReferenceCollectionItem;
  data_type: AttributeDataType;
  product_ref?: ProductReference;
};

/**
 * @see /server/src/agilis/models/constant.py AttributeInputType
 * uncomment commented lines when they are supported. In future potentially this
 * could be split into multiple types.
 */
export type AttributeInput =
  | "date"
  // | "document"
  | "form_field"
  // | "image"
  | "link"
  | "multiline_entry"
  | "multi_select"
  | "numeric"
  | "single_select"
  // | "timestamp"
  | "toggle"
  | "checkbox"
  | "product_reference";

/**
 * @see AttributeDataType
 */
export type AttributeDataType =
  | "text"
  | "numeric"
  | "date"
  // | "timestamp"
  | "boolean"
  // | "document"
  // | "url"
  | "enum";

/**
 * @see /server/src/agilis/models/constant/AttributeObjectType
 * These should really be sorted into string/boolean/numeric values
 */
export type AttributeObjectType =
  | "product_name"
  | "brand_name"
  | "product_id"
  | "produced_by"
  | "product_line"
  | "product_description"
  | "chemical_name"
  | "cas_number"
  | "ec_number"
  | "inchi_key"
  | "inci_name"
  | "molecular_formula"
  | "synonyms"
  | "seo_page_title"
  | "seo_meta_title"
  | "seo_meta_keywords"
  | "seo_meta_description"
  | "product_group_1"
  | "product_group_2"
  | "product_group_3"
  | "applications"
  | "value_propositions"
  | "functions"
  | "industries"
  | "market_segments"
  | "sku_id"
  | "packaging_type"
  | "packaging_unit"
  | "packaging_volume"
  | "packaging_description"
  | "property_name"
  | "specification_name"
  | "value"
  | "unit"
  | "is_a_sample"
  | "test_method";

/**
 *  @see server/src/agilis/docs/odm/AttributeSchema.yaml
 * Schema for attributes
 */
type AttributeSchemaBase = {
  is_required: boolean;
  id: UUID;
  name: "display_name" | string;
  display_name: string;
  description: string | null;
  is_multiple_choice?: boolean;
  default_values: null | AttributeValue[];
  choices?: string[];
  // There's an EP that gives all the possible input types: /v2/pim/input-types
  // input_type: AttributeInput;
  values: AttributeValue[];
  is_system: boolean;
  list_name: string | null;
  list_id: UUID;
  is_filterable: boolean;
  object_type: AttributeObjectType;
  new_object_type: string;
  is_restricted: boolean;
  is_editable: boolean;
  translations: TranslationsSchema[];
};

type StringOrNullValues = {
  input_type: Extract<
    AttributeInput,
    | "date"
    | "form_field"
    | "single_select"
    | "numeric"
    | "multiline_entry"
    | "multi_select"
    | "product_reference"
  >;
};

type BooleanValues = {
  input_type: Extract<AttributeInput, "toggle" | "checkbox">;
};

type ObjectValues = {
  input_type: Extract<AttributeInput, "link">;
};

export type AttributeSchema = AttributeSchemaBase &
  (StringOrNullValues | BooleanValues | ObjectValues);

/**
 * @see EnumVariantSchema
 * currently text is the only possible variant_type. Subject to change in the future
 */
export type EnumVariantSchema = {
  attribute_enum_metadata_id: UUID;
  id: UUID;
  variant: string;
  variant_type: Extract<"text", AttributeDataType>;
};

/**
 * @see server/src/agilis/docs/odm/AttributeArgSchema.yaml
 * request body to create/update an attribute
 */
export type AttributeArgSchema = Omit<AttributeSchema, "id">;

/**
 * @see AttributeSchema, but paginated.
 */
export type PaginatedAttributeSchema = WithPagination<{
  data: AttributeSchema[];
}>;

/**
 * Enum of possible PIM classifications.
 */
export enum PIMAttributesClassification {
  ATTRIBUTES,
  GROUPS,
  COLLECTIONS,
}

export enum PIMLists {
  LISTS,
  ACTIVITY,
}

/**
 * @see server/src/agilis/docs/odm/CollectionColumnCreationSchema.yaml
 */
export type CollectionColumnCreationSchema = {
  attribute_id: UUID;
  display_name?: string;
  is_required: boolean;
  is_editable: boolean;
};

/**
 * @see server/src/agilis/docs/odm/CollectionRowCreationSchema.yaml
 */
export type CollectionRowCreationSchema = {
  attribute_id: UUID;
  default_values?: string[];
};

/**
 * @see server/src/agilis/docs/odm/CollectionSchema.yaml
 */
export type CollectionSchema = {
  id: UUID;
  name: string;
  display_name: string;
  description?: string;
  columns: AttributeSchema[];
  rows: CollectionRowSchema[];
  value_rows?: CollectionValueRowSchema[];
  attribute_count: number;
  is_system: boolean;
  values?: unknown[]; // FIXME
  group_type: string;
  object_type: string;
  is_restricted: boolean;
};

/**
 * @see server/src/agilis/docs/odm/CollectionPaginatedOutputSchema.yaml
 */
export type CollectionPaginatedOutputSchema = WithPagination<{
  data: CollectionSchema[];
}>;

/**
 * @see server/src/agilis/docs/odm/CollectionCreationSchema.yaml
 */
export type CollectionCreationSchema = {
  parent_group_id?: UUID;
  name: string;
  display_name?: string;
  description?: string;
  columns: CollectionColumnCreationSchema[];
  rows: CollectionRowCreationSchema[];
};

/**
 * @see server/src/agilis/docs/odm/CollectionPatchArgsSchema.yaml
 */
export type CollectionPatchArgsSchema = CollectionCreationSchema & {
  columns_to_delete?: string[];
  rows_to_delete?: string[];
};

/**
 * @see server/src/agilis/docs/odm/CollectionRowSchema.yaml
 */
export type CollectionRowSchema = {
  id: UUID;
  attributes: AttributeSchema[];
};

export type CollectionValueRowSchema = {
  row_id: UUID;
  values: AttributeValue[];
  is_editable: boolean;
};

/**
 * @see server/src/agilis/docs/odm/ShortListSchema.yaml
 */
export type ShortListSchema = {
  id: UUID;
  name: string;
  display_name?: string;
  is_system: boolean;
  count: number; // only shows count for active list items
  is_frozen: boolean; // indicates whether it's in use or not
  parent?: {
    id: UUID;
    name: string;
  };
};

/**
 * @see server/src/agilis/docs/odm/TenantListPaginatedOutputSchema.yaml
 */
export type PaginatedListSchema = WithPagination<{ data: ShortListSchema[] }>;

/**
 * @see server/src/agilis/docs/odm/ListArgSchema.yaml
 */
export type ListPostArgSchema = {
  name: string;
  display_name?: string;
  choices?: string[];
};

export type ListPatchArgSchema = {
  name: string;
  display_name?: string;
  is_deleted: boolean;
};

export type ListItemPatchArgSchema = {
  name: string;
  is_deleted?: boolean;
};

export type ListItemSchema = {
  id: UUID;
  name: string;
  is_frozen: boolean; // indicates if this can be edited or not
};

export type PaginatedListItemSchema = WithPagination<{
  data: ListItemSchema[];
}>;

/**************************
 * TEMPLATE TYPES
 *************************/

/**
 * GET /v2/tenants/{id}/pim/templates
 * Currently no paginated variant of this, just an array.
 * @see /server/src/agilis/docs/odm/AttributeTemplateSchema.yaml
 */
export type AttributeTemplateSummarySchema = {
  template_id: UUID;
  template_name: string;
  display_name: string | null;
  description: string | null;
  is_frozen: boolean;
  tenant_id: UUID;
  number_of_archived_products: number;
  number_of_products: number;
};
export type AttributeTemplateSchema = AttributeTemplateSummarySchema & {
  groups: AttributeGroupSummary[];
  collections: CollectionSchema[];
};

/**
 * POST /v2/tenants/{id}/pim/templates
 * @see /server/src/agilis/docs/odm/AttributeTemplateCreationSchema.yaml
 */
export type AttibuteTemplateCreationSchema = {
  name: string;
  template_id?: UUID;
  display_name?: string;
  description?: string;
  groups: { group_id: UUID; display_name?: string };
};

/**
 * PATCH /v2/tenants/{id}/pim/templates/{id}
 * @see /server/src/agilis/docs/odm/AttributeTemplatePatchSchema.yaml
 */
export type AttributeTemplatePatchSchema = {
  name: string;
  display_name?: string;
  description?: string;
  groups: { group_id: UUID; display_name?: string };
  groups_to_delete: UUID[];
};

export type ProductAttributesSummary = {
  product_name: string;
  product_id: string;
  attributes: AttributeSchema[];
};

export type PIMProductBase = {
  description: string | null;
  name: string; // Also an attribute
  id: UUID;
  team_names: string[];
  is_accessible_by_all_customers_and_distributors?: boolean;
  // TODO determine what type to use here. Do not merge this as is
  produced_by: string;
  is_active: boolean;
  last_modified_full: { modified_at: string; modified_by: string | null };
  modified_at: string;
  status: ProductStatusType;
  display_status: ProductStatusType;
  image_url: string | null;
  published_product_id: UUID;
  primary_staged_product_id: UUID;
  slug: string;
  allow_custom_sku: boolean;
  display_completion_score: number;
  completion_score: number;
  chemical_name: string | null;
  product_number: string | null;
};

export type PIMProduct = PIMProductBase & {
  product_schema: {
    is_frozen: boolean;
    collections: CollectionSchema[];
    description: string | null;
    display_name: string;
    template_id: UUID;
    generated_assets: TdsGeneratedAssetSchema[];
    template_name: string;
    groups: AttributeGroupSummary[]; // This type might be wrong
    tenant_id: UUID;
  };
  assets: Assets[];
  asset_external_links: AssetsExternalLinks[];
  is_editable: boolean;
  is_globally_viewable: boolean;
  product_applications: {
    filter_id: UUID;
    id: UUID;
    image_url: string | null;
    name: string;
  }[];
  /**
   * This is NOT all of the attributes in the `identifiers` group. Its a
   * specific subset of special attributes that are frequently displayed on the UI.
   */
  identifiers: Identifiers;
  product_skus: ProductSKU[];
};

export type AssetsExternalLinks = {
  id: UUID;
  url: string;
  name: string;
  is_visible: boolean;
  product_ids: UUID[];
  created_at: string;
  created_by: string;
  modified_at: string;
  modified_by: string;
  number_of_products: number;
  is_editable: boolean;
};

export type PIMProductBasePaginagedOutput = WithPagination<{
  data: PIMProductBase[];
}>;

export type PIMProductPaginatedOutput = WithPagination<{
  data: PIMProduct[];
}>;

// Assets Types

export type PIMAssetsResponse = WithPagination<{
  data: Assets[];
}>;

export type SupportedAssetType = "video" | "image" | "document";

export type SupportedAssetCategoryType = string;

type AssetsBase = {
  id: UUID;
  name: string;
  asset_type: SupportedAssetType;
  asset_category: string;
  category: { id: string; name: SupportedAssetCategoryType };
  language: SupportedLanguage;
  region: string;
  document_url: string;
  modified_at: string;
  modified_by: string;
  number_of_products: number;
  is_downloadable: boolean;
  signed_url: string;
  preview_url: string;
  is_cover_image: boolean;
  product_ids: string[];
  content_type: string | null;
};

type GeneratedAssets = AssetsBase & {
  is_generated: true;
  tds_generated_asset: TdsGeneratedAssetSchema;
};

type NonGeneratedAssets = AssetsBase & {
  is_generated: false;
};

export type Assets = GeneratedAssets | NonGeneratedAssets;
export interface PermissionObjectType {
  quote_requests: string;
  templates: string;
  leads: string;
  attributes: string;
  sample_requests: string;
  groups: string;
  products: string;
  lists: string;
  collections: string;
  assets: string;
  orders: string;
}

export type RoleSummary = {
  id: UUID;
  permission_object_type: PermissionObjectType;
  name: string;
}[];

export type Role = {
  id: UUID;
  name: "Admin" | "Product Manager" | "Sales Manager" | "Standard User";
  permissions: {
    id: UUID;
    is_active: boolean;
    permission: {
      id: UUID;
      name: string;
      object_type: string;
      slug: Permission;
    };
  }[];
};

export type ProductStatusType =
  | "draft"
  | "published"
  | "staged"
  | "unpublished"
  | "unpublished_staged"
  | "archived"
  | "discarded"
  | "bulk_edit_in_progress";

export type ProductStatusesSchema = {
  statuses: ProductStatusType[];
};

export type PIMProductActivity = {
  id: string;
  target_id: string;
  modified_at: string;
  target_type: string;
  version_number: number;
  modified_by_id: string;
  created_at: string;
  status: string;
  last_modified_by: string;
};

export type PIMProductUploadSchema = {
  upload_number: string;
  id: string;
  template_name: string;
  created_at: string;
  created_by: string;
  status: "successful" | "failed" | "in_progress";
  upload_log_url?: string;
};

export type UploadNotification = {
  status: PIMProductUploadSchema["status"];
  number_of_product: number;
  upload_number: string;
};

export type TDSGeneratedAssetRequestSchema = {
  asset_type?: "document";
  asset_category?: "tds";
  sections?:
    | {
        order: number;
        group_or_collection_id: UUID;
        is_full_width: boolean;
      }[]
    | null;
  language: SupportedLanguage;
  region?: string;
  footer_on_last_page_only?: boolean;
  is_active: boolean;
  title: string;
  letterhead_file_id?: UUID | null;
  footer_content?: string;
  footer_image_id?: UUID | null;
};

type TdsGeneratedAssetsSectionBase = {
  order: number;
};

type TdsGeneratedAssetsSectionCollection = TdsGeneratedAssetsSectionBase & {
  group_type: "collection";
  collection: {
    id: string;
    name: string;
    display_name: string;
    description: string;
  };
};

type TdsGeneratedAssetsSectionGroup = TdsGeneratedAssetsSectionBase & {
  group_type: "group";
  group: {
    id: string;
    name: string;
    display_name: string;
    description: string;
  };
};

export type TdsGeneratedAssetsSectionsSchema =
  | TdsGeneratedAssetsSectionCollection
  | TdsGeneratedAssetsSectionGroup;

export type TdsGeneratedAssetSchema = {
  id: UUID;
  asset_type: "document";
  asset_category: "tds";
  sections: TdsGeneratedAssetsSectionsSchema[];
  is_active: boolean;
  language: SupportedLanguage;
  region?: string;
  footer_on_last_page_only: boolean;
  footer_content?: string;
  number_of_assets: number;
  title: string;
  letterhead_document?: TDSDocument;
  footer_document?: TDSDocument;
};

export type TdsGenerateAssetJobSchema = {
  id: UUID;
  status: "pending" | "failed" | "finished";
  tenant_id?: UUID;
  product_id?: UUID;
  tds_generated_asset_id?: UUID;
  tds_asset?: Assets;
};

export type UploadedAssetSchema = {
  id: UUID;
  asset_type: SupportedAssetType;
  category: { name: SupportedAssetCategoryType; id: UUID };
  language: SupportedLanguage;
  number_of_assets: number;
  modified_by: string;
  modified_at: string;
};

export type UploadAssetRequestSchema = {
  asset_type: SupportedAssetType;
  category_id: UUID;
  language: SupportedLanguage;
  files: FormData;
};

export type UploadedAssetInProgressSchema = {
  id: UUID;
  file_name: string;
  asset_name: string;
  size: number;
  errors?: string;
};

export type UploadAssetsResponseSchema = {
  id: UUID;
  number: string;
  data: UploadedAssetInProgressSchema[];
  asset_type: SupportedAssetType;
  category: { name: SupportedAssetCategoryType; id: UUID };
  language: SupportedLanguage;
};

export type PortfolioControlsSchema = {
  id: UUID;
  filter_type: string;
  attribute: AttributeSchemaBase;
  display_name: string;
  is_list: boolean;
  is_searchable: boolean;
  is_filterable: boolean;
};

export type ProductExportStatusSchema = {
  id: UUID;
  number: string;
  query_parameters: Record<string, number | string | Array<unknown>>;
  total_jobs: number;
  total_products: number;
  status: "successful" | "failed" | "in_progress";
  documents: ProductSelectorCSVDocument[];
};

export type GridItemProps = {
  can_edit: boolean;
  can_change_visibility: boolean;
  can_delete: boolean;
  can_external_download: boolean;
  can_view_details: boolean;
};
